<template>
  <div>
    <b-col class="p-0">
      <b-card-actions
        ref="refFilter"
        :title="$t('Filters')"
        action-collapse
        :collapsed="collapsedFilter"
      >
        <b-card-body class="p-0">

          <b-row>
            <!-- Name -->
            <b-col
              cols="12"
              md="6"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('Name') }}</label>
              <b-form-input
                :value="nameFilter"
                class="d-inline-block mr-1"
                :reduce="val => val.value"
                @input="(val) => $emit('update:nameFilter', val)"
              />
            </b-col>

            <!-- Channel -->
            <b-col
              cols="12"
              md="6"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('Channel') }}</label>
              <b-form-input
                :value="channelFilter"
                class="d-inline-block mr-1"
                :reduce="val => val.value"
                @input="(val) => $emit('update:channelFilter', val)"
              />
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <!-- Button -->
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
              style="text-align: right;"
            >
              <div>
                <b-button
                  variant="outline-primary"
                  @click="resetFilter"
                >
                  {{ `${$t('Reset Filter')}` }}
                </b-button>
                &nbsp;
                <b-button
                  variant="info"
                  @click="onCollapsed"
                >
                  {{ `${$t('Collapse')}` }}
                </b-button>
              </div>
            </b-col>
          </b-row>

        </b-card-body>
      </b-card-actions>
    </b-col>
  </div>
</template>

<script>
import {
  BCardBody, BRow, BCol,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardBody,
    BCardActions,
    BFormInput,
    BButton,
  },
  props: {
    collapsedFilter: {
      type: Boolean,
      default: false,
    },
    nameFilter: {
      type: [String, null],
      default: null,
    },
    channelFilter: {
      type: [String, null],
      default: null,
    },
  },
  methods: {
    resetFilter() {
      this.$emit('reset-filter')
    },
    onCollapsed() {
      this.$refs.refFilter.triggerCollapse()
    },
  },
}
</script>

<style lang="scss">
.card {
  .card-title {
    font-size: 1.07rem;
  }
  .card-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
</style>
