import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultLineChannel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/tools/get_default_line_channel', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListLineChannel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/tools/get_list_line_channel', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getLineChannel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/tools/get_line_channel', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addLineChannel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/tools/add_line_channel', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editLineChannel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/tools/edit_line_channel', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteLineChannel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/tools/delete_line_channel', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    lineChannelOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/tools/line_channel_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
